.mc-auto-popmenu {
  //background-color: rgba(0, 1, 1, 0.2);
  position: absolute;
  z-index: 300;
  //max-width: 500px;
  border-radius: 2px;
  outline: none;
  box-shadow: 0 3px 6px -4px #0000001f, 0 6px 16px #00000014, 0 9px 28px 8px #0000000d;
  padding: 5px 0;
  background-color: #ffffff;
  transform-origin: left top;

  ul {
    padding: 0 5px;
    margin: 0;
    overflow: auto;
    //max-height: 200px;
    width: 100%;

    .mc-auto-popmenu-focus {
      background-color: #e5e5e5;
    }

    li {
      //white-space: nowrap;
      list-style: none;
      padding: 6px;
      overflow: hidden;
      display: block;
      //text-overflow: ellipsis;
      //min-height: 28px;
      //line-height: 28px;

      &:hover {
        background: var(--ion-color-primary);
        color: var(--ion-color-primary-contrast);
        //background-color: #c3d9ff;
        //background-color: rgba(56, 128, 255, 0.3);
      }
    }
  }
}

.mc-auto-popmenu-input {
  //border: solid 1px var(--ion-color-primary);
  border-bottom: solid 1px #cccccc;
}

.mc-auto-popmenu-search-icon {
  width: 32px;
  height: 32px;
  position: absolute;
  right: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;

  ion-icon {
    opacity: 0.6;
  }
}

