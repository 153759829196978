// 紧凑型表格排版
.dn-compact-panel, .dn-compact-panel-form {
  //background: #ffffff;

  // 主题
  .dn-cp-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 16px 5px;

    .dn-cp-title {
      font-weight: 500;
      position: relative;
      padding: 5px 0 5px 12px;

      &:before {
        content: " ";
        position: absolute;
        width: 4px;
        background-color: var(--ion-color-tertiary);
        height: 52%;
        border-radius: 2px;
        top: 24%;
        left: 0;
      }
    }

    .dn-cp-header-buttons {
      display: flex;
      justify-content: space-between;
      align-items: center;

      ion-button {
        margin-left: 12px;
      }
    }

    ion-button {
      border: solid 1px var(--ion-color-tertiary);
      margin: 0;
      min-height: 32px;
      border-radius: 5px;
      --padding-start: 10px;
      display: flex;
      align-items: center;

      ion-icon {
        font-size: .95rem;
      }

    }
  }

  .dn-cp-header2 {

    .dn-cp-header2-inner {
      min-height: 40px;
      padding: 5px 16px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      background-color: #fff;
      //border-bottom: solid 1px #cccccc;

      span:first-child {
        font-weight: 500;
      }

      ion-button {
        font-size: .9em;
      }
    }
  }

  .dn-cp-header2:not(:first-child) ion-col .dn-cp-header2-inner {
    margin-top: 6px;
  }

  .dn-cp-grid {
    --ion-grid-column-padding: 22px;
    --ion-grid-padding: 22px;
    margin: 0;
    padding: 0;
    position: relative;
    -webkit-user-select: text !important;
    user-select: text !important;

    ion-row {
      //background-color: #fff;

      ion-col {
        // 需要边框
        //padding: 1px;
        .form-item {
          margin: 0;
        }

        ion-item {
          margin: 0;
          height: 100%;
          --min-height: 100%;
          --padding-bottom: 3px;
          --padding-top: 3px;
          //--inner-border-width: 0;

          .mc-select {
            margin: 0;
          }

          ion-label {
            word-wrap: break-word;
            white-space: normal;
            margin: 5px 5px 5px 0;

            //&:first-child {
            //  //margin: 5px 0;
            //  //-webkit-user-select: text !important;
            //  //user-select: text !important;
            //}
          }

          .field-label {
            margin: 5px;
            color: #000000;
            white-space: pre-line;
          }

          .field-content {
            padding-left: 12px;
            white-space: pre-line;
            color: #000000;
          }
        }


      }
    }

    ion-item-group {
      ion-item-divider {
        --inner-padding-end: 0px;
        height: 40px;
        padding-bottom: 3px;
        --padding-start: 0;
        --padding-end: 0;
        background-color: #F6F6F6;
      }
    }
  }

  ion-item.form-input {
    --padding-bottom: 5px !important;

    ion-label:first-child {
      word-wrap: break-word;
      white-space: normal !important;
      margin-right: 12px;
    }

    ion-input, .mc-select, ion-select, ion-textarea {
      border-bottom: solid 1px #cccccc;
    }

    ion-input, ion-select, ion-textarea {
      //--padding-top: 5px;
      //--padding-end: 5px;
      //--padding-bottom: 5px;
      //--padding-start: 5px;
      --padding-top: 6px;
      --padding-end: 5px;
      --padding-bottom: 7px;
      --padding-start: 5px;
    }
  }


  //ion-item.form-input {
  //  ion-label:first-child {
  //    color: var(--ion-color-primary) !important;
  //  }
  //}
}

// *******************************************
// *************** plt-desktop ***************
// *******************************************
html.plt-desktop {
  .dn-compact-panel, .dn-compact-panel-form {
    //margin: 5px 16px 3px;

    mc-select-staff, mc-select-department {
      ion-item {
        font-size: 15px;
      }
    }

    ion-label, ion-input, mc-select, ion-select, ion-textarea {
      font-size: 15px;
    }

    .dn-cp-header ion-button {
      ion-label {
        font-size: 14px;
      }
    }

    .dn-cp-grid-small {
      ion-row {
        ion-col {
          padding: 1px;
        }
      }
    }
  }

  .dn-compact-panel {
    .dn-cp-grid {
      ion-row {
        ion-col {
          // 边框留白
          padding: 1px;

          ion-item {
            --padding-bottom: 3px;
            --padding-top: 3px;
          }
        }
      }
    }
  }

  .dn-compact-panel-form {
    .dn-cp-grid {
      ion-row {
        ion-col {
          // 边框留白
          padding: 3px;

          ion-item {
            --padding-bottom: 5px;
            --padding-top: 3px;
          }
        }
      }
    }
  }
}

// *********************************************
// *************** plt-mobileweb ***************
// *********************************************
html.plt-mobileweb {
  .dn-compact-panel, .dn-compact-panel-form {
    //margin: 0;
    font-size: 17px;

    .dn-cp-grid {
      ion-row {
        ion-col {
          // 需要边框
          padding: 2px 0;
        }
      }
    }
  }

  .dn-cp-header2:not(:first-child) ion-col .dn-cp-header2-inner {
    margin-top: 3px;
  }

  .dn-compact-panel-form .dn-cp-grid ion-row ion-col {
    // 移动端下留白
    padding: 2px 0;
  }
}

// *********************************************
// ******************* color *******************
// *********************************************
// 灰色:详情下label=灰色，input=蓝色
.dn-compact-pane {
  .color-gray {
    ion-item:not(.form-input) {
      ion-label:first-child {
        color: #777777 !important;
      }
    }
  }
}

.dn-compact-panel-form {
  .color-gray {
    ion-item {
      ion-label:first-child {
        color: #777777 !important;
      }
    }
  }
}

.mc-modal {
  .modal-body {
    .dn-compact-panel-form {
      margin: 0;

      ion-item {
        --padding-start: 16px;
        --padding-end: 16px;
      }
    }
  }
}
