$node-height: 32px;

tree.mc-tree {

  .value-container {
    display: flex;
    height: $node-height;
    align-items: center;


    .node-value {
      width: calc(100% - $node-height);
      line-height: $node-height;

      &:after {
        display: none;
      }
    }

    .node-selected {
      font-weight: 500;
      color: var(--ion-color-primary);
    }

    .folding {
      margin-right: 6px;
    }

    .fa-caret-down, .fa-caret-right, .mt-btn-menu {
      //margin-bottom: 6px;
      width: 18px;
      height: 18px;
      //vertical-align: middle;
      background-position: 50% 50%;
      background-repeat: no-repeat;
      background-size: contain;
    }

    .fa-caret-down {
      background-image: url('/assets/icon/caret-down.svg');
    }

    .fa-caret-right {
      background-image: url('/assets/icon/caret-right.svg');
    }

    .fa-leaf {
      width: 18px;
      margin-right: 0;
    }

    .mt-btn-menu {
      margin: 7px;
      //padding:7px;
      background-image: url('/assets/icon/ellipsis-vertical.svg');
    }

    .node-left-menu {
      margin-left: auto;
      height: $node-height;
    }
  }

  // 为了右侧菜单，设置
  ul.tree {
    li {
      position: relative;

      .node-left-menu {
        display: none;
      }

      .node-menu {
        position: absolute;
        right: 0;
        top: 32px;
      }

      .value-container:hover {
        .node-left-menu {
          display: block;
        }
      }
    }
  }

}

//ul.tree {
//  > li:has(>div>div.node-selected) {
//    background: red;
//  }
//}
