@font-face {
  font-family: 'number-font';
  //src: url('https://qw-1314738843.cos.ap-chongqing.myqcloud.com/static/font/number.ttf');
  src: url('https://qw-1314738843.cos.ap-chongqing.myqcloud.com/static/font/number.ttf');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'price-font';
  //src: url('../assets/font/Montserrat-Regular.ttf');
  //Medium
  src: url('https://qw-1314738843.cos.ap-chongqing.myqcloud.com/static/font/Montserrat-Medium.ttf');
  //font-weight: bold;
  //font-style: normal;
}

@font-face {
  font-family: 'fixed-width-font';
  //src: url('https://qw-1314738843.cos.ap-chongqing.myqcloud.com/static/font/LiberationMono-Regular-4.ttf');
  src: url('https://qw-1314738843.cos.ap-chongqing.myqcloud.com/static/font/RobotoMono-Regular-9.ttf');
  //font-weight: bold;
  //font-style: normal;
}

.fs-24 {
  font-size: 24px;
}

.mb-12 {
  margin-bottom: 12px !important;
}

.mt-3 {
  margin-top: 3px !important;
}

.mt-6 {
  margin-top: 6px !important;
}

.mt-12 {
  margin-top: 12px !important;
}

.mt-24 {
  margin-top: 24px !important;
}

.mt-36 {
  margin-top: 36px !important;
}

.pl-8 {
  padding-left: 8px !important;
}

.pb-3 {
  padding-bottom: 3px !important;
}

.pb-6 {
  padding-bottom: 6px !important;
}

.pb-8 {
  padding-bottom: 8px !important;
}

.pb-12 {
  padding-bottom: 12px !important;
}

.pb-16 {
  padding-bottom: 16px !important;
}

.pb-32 {
  padding-bottom: 32px !important;
}

.pb-48 {
  padding-bottom: 32px !important;
}

.pt-8 {
  padding-top: 8px !important;
}

.pt-32 {
  padding-top: 32px !important;
}

.color-primary {
  color: var(--ion-color-primary);
}

.mw-5em {
  width: 5em;
  min-width: 5em !important;
}

.mw-6em {
  width: 6em;
  min-width: 6em !important;
}

.mw-7em {
  width: 7em;
  min-width: 7em !important;
}

.mw-8em {
  width: 8em;
  min-width: 8em !important;
}

.mw-9em {
  width: 9em;
  min-width: 9em !important;
}

.bg-fff {
  background-color: #fff !important;
}

.mw-600 {
  max-width: 600px !important;
}

.color-secondary {
  color: var(--ion-color-secondary);
}

.color-tertiary {
  color: var(--ion-color-tertiary);
}

.color-success {
  color: var(--ion-color-success);
}

.color-warning {
  color: var(--ion-color-warning);
}

.color-dark {
  color: var(--ion-color-dark);
}

.color-danger {
  color: var(--ion-color-danger);
}

.mc-alert {
  --min-width: 160px;
  --max-width: 200px;
  //--width: 200px;
  --border-radius: 5px !important;
  --background: #333333;
  --color: #ffffff;
  text-align: center;
}

.mc-error, .mc-success, .mc-warn {
  --min-width: 180px;
  --max-width: 280px;
  //--width: 200px;
  --border-radius: 5px !important;
}

.placeholder {
  color: var(--ion-color-light-contrast);
}

// 登录界面验证码输入框
.login-input-code {
  .native-input {
    &::placeholder {
      font-size: 1rem;
    }
  }
}

.mc-alert-button-confirm {
  font-weight: 500;
}

.mc-alert-button-cancel {
  color: var(--ion-color-medium) !important;
}

.content-bg {
  //--background: var(--ion-color-light);
  --background: var(--mc-background-color);
}

html.plt-desktop {
  .border-radius-panel {
    padding: 16px;
    border-radius: 12px;
    border: solid 1px var(--ion-border-color);
  }
}

.border-radius-panel {
  padding: 6px;
  background: #fff;
}

.mc-item-options {
  color: #ffffff;

  ion-item-option {
    font-weight: 500;
    width: 60px;
  }

  .item-option-disabled {
    font-weight: normal !important;
    opacity: 0.5;
    //color: #cccccc;
  }

  .confirm_option {
    width: 50%;
  }
}


.mc-item-option-1 {
  width: 130px !important;

  ion-item-option {
    width: 130px !important;
  }
}

.mc-item-option-2 {
  width: 90px*2 !important;

  ion-item-option {
    width: 90px !important;
  }
}

.mc-item-option-3 {
  width: 60px*3 !important;

  //.confirm_option {
  //  width: 90px !important;
  //}
}

.mc-item-option-4 {
  width: 60px*4 !important;
}

.mc-dropdown {
  cursor: pointer;
  width: 36px;
  height: 36px;
  display: inline-flex;
  align-items: center;
  justify-content: center;

  ion-icon {
    font-size: 14px;
    color: #5b6b79;
  }

  &:hover {
    border-radius: 8px;
    background: var(--mc-hover-background-color);
  }
}

// button ///////////////////////////////////
.mc-ion-btn-icon {
  color: var(--ion-color-danger);
  --background-hover: var(--mc-hover-background-color);
  margin: 0;
  width: 36px;
  min-height: 36px;
  --padding-end: 0px;
  --padding-start: 0px;
  --padding-top: 0px;
  --padding-bottom: 0px;
  --border-radius: 10px;
  --color-hover: #333;

  ion-icon {
    font-size: 20px;
  }
}

.mc-item-selected {
  background-color: var(--mc-select-background-color);
}
