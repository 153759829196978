
ion-item {
  .mc-buttons {
    display: flex;
    flex: 1 1 0%;
    width: min-content;
    max-width: 100%;
  }
}

.mc-button {
  cursor: pointer;
  width: 33.33%;
  padding: 3px 5px;
  text-align: center;
  color: var(--ion-color-primary);

  &:hover {
    color: var(--color-activated);
  }
}
