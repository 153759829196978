@import "base.scss";
@import "mc-button.scss";
@import "mc-modal.scss";
@import "mc-auto-popmenu.scss";
@import "mc-form.scss";
@import "mc-datetime.scss";
@import "mc-compact-panel.scss";
@import "cdk.scss";
@import "mc-easy-table.scss";
@import "mc-ng2-tree.scss";
@import "mc-grid.scss";
@import "mc-page.scss";


.plt-desktop {

  //ion-content {
  //  --padding-top: 0 !important;
  //  --padding-start: 32px;
  //  --padding-end: 32px;
  //  --padding-bottom: 50px;
  //}

  .mc-datetime {
    ion-content {
      --padding-start: 0 !important;
      --padding-end: 0 !important;
      --padding-bottom: 0 !important;
    }
  }

}

ion-item {
  --background: transparent;
}

// 桌面终端下 二级分屏 /////////////////////
ion-split-pane.sub-split-pane {
  //--side-min-width: 260px;
  //--side-max-width: 320px;
  --border: none ion-menu {
    padding: 0 24px;
  }

}

.desktop-padding-end {
  --padding-end: 24px;
}


//.form-item {
//  margin: 8px 0;
//  --min-height: 50px;
//  --padding-start: 16px;
//  --padding-end: 16px;
//  --border-color: #CCCCCC;
//  --inner-padding-end: 0px;
//
//  ion-button {
//    height: 30px;
//    font-size: 14px;
//    --padding-start: 0.75em;
//    --padding-end: 0.75em;
//  }
//
//  ion-label:first-child {
//    font-size: 17px;
//    min-width: 5em;
//    max-width: 5em;
//    color: #777777;
//    font-weight: 400;
//    text-align: right;
//    padding-right: 8px;
//
//    &.required {
//      position: relative;
//
//      &:after {
//        content: " *";
//        font-size: 14px;
//        color: var(--ion-color-danger);
//      }
//    }
//  }
//
//  ion-label:last-child {
//    user-select: text !important;
//  }
//
//  ion-list-header {
//    font-size: 17px;
//  }
//
//  ion-item-divider, ion-list-header {
//    //position: relative;
//    --inner-padding-end: 0px;
//    height: 40px;
//    padding-bottom: 3px;
//    --padding-start: 16px;
//    --padding-end: 16px;
//    background-color: #F6F6F6;
//
//    ion-label:first-child {
//      color: var(--ion-color-tertiary);
//      //color: var(--ion-color-dark);
//      font-weight: 500;
//      font-size: 1.05em;
//      position: relative;
//      padding-left: 12px;
//
//      span:first-child {
//        color: #181818;
//      }
//
//      &:before {
//        content: " ";
//        position: absolute;
//        width: 4px;
//        background-color: var(--ion-color-tertiary);
//        height: 66%;
//        border-radius: 2px;
//        top: 17%;
//        left: 0;
//      }
//    }
//  }
//
//
//  ion-item {
//    --background: transparent;
//    --padding-start: 16px;
//    --padding-end: 16px;
//    --border-color: #CCCCCC;
//    --min-height: 50px;
//    --inner-padding-end: 0px;
//
//    ion-label:first-child {
//      min-width: 5em;
//      max-width: 5em;
//      color: #777777;
//      font-weight: 400;
//    }
//
//    ion-note {
//      //padding-left: 5px;
//      margin-top: 12px;
//      color: var(--ion-color-dark);
//      margin-bottom: 10px;
//    }
//
//  }
//
//  ion-label:not(:first-child), ion-input, ion-textarea, .text-full {
//    font-weight: 400;
//    color: var(--ion-color-dark);
//  }
//
//  ion-select.md {
//    --padding-start: 8px;
//    //min-width: 100%;
//  }
//
//  ion-datetime {
//    width: 100%;
//  }
//
//  ion-datetime::part(text) {
//    font-weight: 500;
//    color: var(--ion-color-dark);
//  }
//
//  .right-border:after {
//    content: " ";
//    border-right: solid 1px #D8D8D8;
//    height: 20px;
//  }
//
//  .left-border:after {
//    content: " ";
//    border-left: solid 1px #D8D8D8;
//    height: 20px;
//  }
//
//  .label-button {
//    --color: #00D1FF !important;
//    text-decoration: none;
//  }
//
//  .checkbox-inline {
//    align-items: center;
//    display: flex;
//    position: relative;
//    //flex: 1 !important;
//    width: 100%;
//    height: 40px;
//
//    .checkbox-item {
//      margin-right: 24px;
//      position: relative;
//      width: 100%;
//      height: 40px;
//
//      .checkbox {
//        position: absolute;
//        white-space: nowrap;
//
//        ion-checkbox, ion-label {
//          display: inline-block;
//          vertical-align: middle;
//        }
//
//        ion-checkbox {
//          width: 22px;
//          height: 22px;
//        }
//      }
//    }
//  }
//
//  .info-title {
//    color: #666666;
//  }
//
//  .info-content {
//    font-size: inherit;
//    font-weight: 500;
//  }
//
//
//  .info-block {
//    padding: 8px 0;
//    color: var(--ion-color-dark) !important;
//    font-weight: 500;
//
//    span {
//      padding-right: 8px;
//    }
//  }
//
//  ion-input[type=hidden] {
//    display: none;
//  }
//
//  ion-select {
//    display: flex;
//    flex: 1;
//    max-width: 100%;
//    --padding-end: 18px;
//    --padding-start: 0;
//
//    &::part(icon) {
//      display: none;
//    }
//
//    &::part(text) {
//      //  background-image: url(https://www.iconfinder.com/data/icons/ios-11-ui-elements-vol-1/29/25_dropdown_menu_down_arrow-512.png);
//      //  background-position: right;
//      //  background-repeat: no-repeat;
//      //  background-size: 50%;
//      white-space: pre-line;
//    }
//  }
//
//  ion-datetime {
//    --padding-start: 0;
//  }
//
//  ion-note {
//    -webkit-user-select: text !important;
//    -moz-user-select: text !important;
//    -ms-user-select: text !important;
//    user-select: text !important;
//  }
//
//  // 整行文本块使用
//  .text-full {
//    color: var(--ion-color-dark);
//    min-width: 100% !important;
//    width: 100% !important;
//    white-space: normal !important;
//    -webkit-user-select: text !important;
//    -moz-user-select: text !important;
//    -ms-user-select: text !important;
//    user-select: text !important;
//  }
//
//  //.xh {
//  //  text-align: center;
//  //  min-width: 2em;
//  //  max-width: 2em;
//  //  font-family: "number-font";
//  //}
//}
