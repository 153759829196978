mc-modal2 {
  margin: 0 auto;
}

.mc-dialog-modal, .mc-dialog-modal-child {
  --height: auto;
  --width: auto;
  --background: transparent;
  --box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px !important;
}

.mc-dialog-modal-child {
  //--box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px !important;
  --backdrop-opacity: var(--ion-backdrop-opacity, 0.12) !important;
}

.mc-dialog {
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  color: var(--bs-modal-color);
  pointer-events: auto;
  background-color: var(--mc-background-color);
  background-clip: padding-box;
  border: solid 1px var(--ion-border-color);
  border-radius: 12px;
  outline: 0;


  .box-shadow {
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  }

  header {
    display: flex;
    flex-shrink: 0;
    align-items: center;
    justify-content: space-between;
    padding: 16px;
    border-bottom: solid 1px var(--ion-border-color);

    .md-header-left {
      display: flex;
      align-items: center;

      ion-icon:first-child {
        padding-right: 6px;
      }
    }


  }

  .md-body {
    padding: 16px;
    min-height: 50px;
  }

  .md-footer {
    position: relative;
    padding: 10px 16px;
    border-top: solid 1px var(--ion-border-color);
    display: flex;
    flex-shrink: 0;
    flex-wrap: wrap;
    align-items: center;

    ion-button {
      min-width: 88px;
      min-height: 32px;
    }

    .md-footer-right {
    }

    .md-footer-left {
      position: absolute;
      left: 16px;
    }

    .md-icon-btn {
      cursor: pointer;
      width: 42px;
      height: 42px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      border-radius: 8px;

      ion-icon {
        font-size: 20px;
      }

      &:hover {
        background: var(--mc-hover-background-color);
      }
    }
  }

  .md-footer-end {
    justify-content: flex-end;
  }
}

.cdk-dialog-container {
  outline: 0 !important;
}


// menu //////////

.cdk-menu {
  //display: inline-flex;
  //flex-direction: column;
  //min-width: 80px;
  //max-width: 180px;
  background-color: rgba(255, 255, 255);
  //padding: 6px 0;
  //border: solid 1px #eee;
  //box-shadow: 0px 0px 8px 1px rgba(105, 117, 120, 0.15);
  box-shadow: 0 4px 24px 0 rgba(62, 57, 107, .18);
  border: none;
  padding: 8px;
}

.cdk-menu-item,
.example-standalone-trigger {
  width: 100%;
  background-color: transparent;
  cursor: pointer;
  border: none;

  user-select: none;
  min-width: 64px;
  line-height: 36px;
  padding: 0 16px;

  display: flex;
  align-items: center;
  flex-direction: row;
  flex: 1;
  outline: 0 !important;

}

.cdk-menu-item:hover {
  background-color: #f3f5f7;
}

.cdk-menu-item:active {
  background-color: rgb(233, 233, 233);
}

.example-standalone-item {
  background-color: rgb(239, 239, 239);
}

.example-standalone-item:hover {
  background-color: rgb(208, 208, 208);
}

.example-standalone-item[aria-expanded='true'] {
  background-color: rgb(208, 208, 208);
}

