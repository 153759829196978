$padding-size: 24px;

// pc相关全局样式
.mc-dynamic-page-header {
  //padding-top: 6px;
  display: flex !important;
  justify-content: space-between;
  align-items: center;
  background: var(--mc-background-color);

  span:first-child {
    font-size: 1.1em;
    font-weight: 500;
    opacity: .8;
    white-space: nowrap;
    padding-right: 32px;
    padding-left: 16px;
  }
}


html.plt-desktop {

  ion-header.mc-dynamic-page-header {
    padding: 0 24px;
    height: 80px;
  }

  ion-content.mc-page-content {
    --padding-top: 0 !important;
    --padding-start: 24px;
    --padding-end: 24px;
    --padding-bottom: 24px;
  }

  ion-split-pane {
    --side-min-width: 260px;

    ion-menu {
    }
  }

}

html.plt-mobileweb {
  ion-header.mc-dynamic-page-header {
    height: 52px;
    font-weight: 500;
    //}
    //
    //.mc-dynamic-page-header {
    //height: 50px;
    padding-left: 16px;
    padding-right: 16px;

    span:first-child {
      position: relative;
      padding-left: 12px;

      &:after {
        content: " ";
        position: absolute;
        width: 4px;
        background-color: var(--ion-color-tertiary);
        height: 80%;
        border-radius: 2px;
        top: 10%;
        left: 0;
      }
    }
  }

  ion-content.desktop-padding-end {
    --padding-end: 0;
  }
}

