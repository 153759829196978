// 一行一个input使用
.form-inline-panel {
  //border-radius: 10px;
  //padding: 1px 0;
  margin: 0;
  --padding-top: 3px;
  --padding-bottom: 3px;
  min-height: 40px;
}

// 多行 每行一个input使用
.form-list-panel {
  //border-radius: 10px;
  //padding-bottom: 12px;

  //mc-form-item {
  .flp-header {
    margin: 0;
    --min-height: 38px !important;
    --padding-top: 6px !important;
    --padding-bottom: 0 !important;
    //--padding-top: 3px;
    //--padding-bottom: 3px;
  }

  //margin: 0;
  //--padding-top: 3px;
  //--padding-bottom: 3px;
  ////--inner-border-width: 0;
  //min-height: 40px;
  //}
}

ion-item.ion-invalid {
  border-bottom: solid 2px var(--ion-color-danger);
  margin-bottom: 0;
  --inner-border-width: 0px;
  height: auto !important;
}

.flp-header, .fip-header, mc-ion-upload ion-item {
  --padding-start: 8px;
  --padding-end: 8px;
  --inner-padding-end: 0;

  span:first-child {
    font-weight: 400;
    font-size: 1em;
    text-indent: 8px;
    color: #777;
  }
}

.mf-label-border {
  font-weight: 400;
  display: block;
  position: relative;
  text-indent: 8px;

  &:after {
    content: " ";
    display: block;
    height: 10px;
    border-left: solid 4px var(--ion-color-primary);
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
  }
}

.form-grey-input {
  ion-input, ion-textarea, .form-input {
    background: var(--ion-color-light);
    --background: var(--ion-color-light);

    border-color: var(--ion-color-light-contrast);
    --border-color: var(--ion-color-light-contrast);
    border-radius: 10px;
    --border-radius: 10px;
    --padding-start: 12px !important;
    //padding-left: 12px;
    --placeholder-color: var(--ion-color-light-contrast);
    --placeholder-opacity: 1;
    min-height: 38px !important;
  }
}

.form-list-panel {
  ion-buttons {
    width: 100%;

    ion-button {
      //font-size: 0.95em;
      margin: 0 6px;
      width: 33.33%;
      //margin: 0;
      --padding-top: 13px;
      --padding-bottom: 13px;
      --border-radius: 12px !important;;
    }
  }

  ion-chip.selected {
    --background: var(--ion-color-primary);
    --color: #ffffff;
  }

}

// 错误提示
.label-error {
  font-weight: 500;
  //color: var(--ion-color-danger);
  text-align: right;
  //position: absolute;
  //right: 16px;
  font-size: 0.90em;
  //background: var(--ion-color-danger);
  color: var(--ion-color-danger);
  padding: 2px 12px;
  //border-radius: 6px;

  ion-icon {
    font-size: 12px;
    margin-right: 2px;
  }
}

// form表单颜色 背景灰，控件白
.dn-item-color-gray-white {
  background: var(--mc-background-color);

  ion-item {
    background-color: #fff;
  }
}
