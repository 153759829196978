.mc-ngx-table {
  table {
    background: #fff;
    font: var(--ion-default-dynamic-font);
    font-size: 17px;
  }


  .easy-tb-no-results {
    text-align: center;
    padding: 30px 0;
  }

  .ngx-pagination-wrapper {
    margin-top: 12px;
    //右侧页码菜单
    .ngx-menu {
      padding-left: 6px;
    }

    // 分页底部留白高度
    .ngx-pagination {
      margin-bottom: 3px;
    }
  }

}

// 搜索关键词头
.easy-search-header {
  font-size: 17px;
  text-align: left;
  padding-bottom: 8px;

  span {
    color: var(--ion-color-danger);
    font-weight: 500;
  }
}
