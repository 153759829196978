.modal-panel {
  padding-top: 5px;
  text-align: left;
  //padding: 3px 16px;
  background: #ffffff;
  border-radius: 8px;
  box-shadow: 0 4px 23px rgba(0, 0, 0, 0.12);

  .modal-title {
    margin: 3px 0;
    display: flex;
    justify-content: center;
    align-items: center;
    padding-top: 10px;
    padding-bottom: 15px;
    //padding: 15px;
    border-bottom: solid 1px #ccc;
    //color: #000000;

    ion-icon {
      font-size: 20px;
      margin-right: 3px;
    }

    label {
      vertical-align: top;
      font-weight: 500;
      line-height: 20px;
      margin-left: 3px;
    }

    .modal-title-close {
      position: absolute;
      right: 24px;
      padding: 10px;
    }
  }

  .modal-header {
    margin: 3px 16px;
    font-weight: 500;
    border-bottom: solid 1px var(--ion-border-color);

    .modal-header-left-title {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 12px 0 18px;

      .modal-title-close {
        padding: 10px;
        position: absolute;
        right: 9px;
        font-size: 20px;
        top: 9px;
        opacity: .5;
        cursor: pointer;

        &:hover {
          opacity: 1;
        }
      }
    }
  }

  .modal-body {
    overflow: auto;
    padding: 3px 16px;

    ion-item {
      --padding-start: 0;
      --padding-end: 0;
      --inner-padding-end: 0px;
    }
  }

  .modal-footer {
    padding: 3px 16px;
    display: flex;
    align-items: center;

    ion-button {
      height: 30px !important;
      //font-size: 17px !important;
    }

    .col-2 {
      width: 50%;
      position: relative;
    }

    .col-3 {
      width: 33.33%;
      position: relative;
    }

    .col-3, .col-2 {
      &:after {
        content: ' ';
        border-right: solid 1px #C4C4C4;
        height: 16px;
        position: absolute;
        top: 22px;
        right: 0;
      }

      &:last-child::after {
        border-right: none;
      }
    }
  }

}

//.mc-modal-fill {
//  .mc-modal-wrapper {
//    .modal-panel {
//      border-radius: 0;
//
//      .modal-title {
//        margin-top: 0;
//      }
//
//      .modal-body {
//        height: calc(100vh - 40px - 60px);
//      }
//    }
//  }
//}


.footer-modal {
  padding-top: 10px;
  background-color: #F6F6F6 !important;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  //padding: 3px 10px;

  .modal-header {
    //margin: 10px 2px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: 40px;


    .footer-modal-title {
      display: flex;
      align-items: center;

      ion-icon {
        font-size: 24px;
        margin-right: 6px;
      }
    }

    .modal-header-left-title {
      padding-top: 0 !important;
    }

    .footer-modal-header-close {
      position: absolute;
      right: 0;

      ion-icon {
        color: #cccccc;
        font-size: 18px;
      }

    }
  }


  .modal-button {
    font-size: 17px;
    margin: 10px 0;
    border-radius: 12px;
    --inner-border-width: 0;
    --inner-padding-start: 20px;
    --inner-padding-end: 20px;

    ion-icon {
      font-size: 20px;
      margin-right: 12px;
    }
  }

  .modal-body {
    form {
      ion-item {
        --padding-start: 16px !important;
        --padding-end: 16px !important;
      }
    }
  }

  .modal-footer {
    padding-bottom: 20px;

    ion-button {
      height: 40px !important;
      //font-size: 17px !important;
    }
  }
}


.footer-modal {
  padding-top: 10px;
  background-color: #F6F6F6 !important;
  border-top-left-radius: 12px;
  border-top-right-radius: 12px;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
  //padding: 3px 10px;

  .modal-header {
    //margin: 10px 2px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    min-height: 40px;

    .modal-header-left-title {
      padding-top: 0 !important;
    }

    .footer-modal-header-close {
      position: absolute;
      right: 0;

      ion-icon {
        color: #cccccc;
        font-size: 18px;
      }
    }
  }

  .footer-modal-title {
    display: flex;
    align-items: center;
  }

  .modal-button {
    font-size: 17px;
    margin: 10px 0;
    border-radius: 12px;
    --inner-border-width: 0;
    --inner-padding-start: 20px;
    --inner-padding-end: 20px;

    ion-icon {
      font-size: 20px;
      margin-right: 12px;
    }
  }

  .modal-body {
    form {
      ion-item {
        --padding-start: 16px !important;
        --padding-end: 16px !important;
      }
    }
  }

  .modal-footer {
    padding-bottom: 20px;

    ion-button {
      height: 40px !important;
      //font-size: 17px !important;
    }
  }
}
